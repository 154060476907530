<template>
  <section data-aller-id="content-brand-hero" class="mb-48 w-full overflow-hidden lg:flex lg:max-h-[660px]">
    <div class="relative justify-evenly lg:flex">
      <div class="relative hidden flex-1 lg:block">
        <picture>
          <source media="(min-width:2000px)" :srcset="`${page.media?.hero?.imageUrl}?width=1000&height=600&format=webp 1000w,${page.media?.hero?.imageUrl}?width=2000&height=1200&format=webp 2000w`" sizes="50vw">
          <source media="(min-width:1440px)" :srcset="`${page.media?.hero?.imageUrl}?width=720&height=600&format=webp 720w,${page.media?.hero?.imageUrl}?width=1440&height=1200&format=webp 720w`" sizes="50vw">
          <source media="(min-width:1024px)" :srcset="`${page.media?.hero?.imageUrl}?width=512&height=600&format=webp 512w, ${page.media?.hero?.imageUrl}?width=768&height=900&format=webp 768w, ${page.media?.hero?.imageUrl}?width=1024&height=1200&format=webp 1024w`" sizes="50vw">
          <source media="(min-width:768px)" :srcset="`${page.media?.hero?.imageUrl}?width=768&height=698&format=webp 768w, ${page.media?.hero?.imageUrl}?width=1152&height=1047&format=webp 1152w, ${page.media?.hero?.imageUrl}?width=1536&height=1396&format=webp 1536w`" sizes="100vw">
          <img :src="`${page.media?.hero?.imageUrl}?width=320&height=548&format=webp`" :srcset="`${page.media?.hero?.imageUrl}?width=320&height=548&format=webp 320w, ${page.media?.hero?.imageUrl}?width=480&height=820&format=webp 480w, ${page.media?.hero?.imageUrl}?width=640&height=1095&format=webp 640w, ${page.media?.hero?.imageUrl}?width=800&height=1368&format=webp 800w`" sizes="100vw" :alt="page.media?.hero?.alt || page.media?.hero?.imageUrl" width="393" height="672">
        </picture>
        <div class="absolute inset-0 bg-black/30 backdrop-blur-3xl" />
      </div>
      <picture class="flex-1">
        <source media="(min-width:2000px)" :srcset="`${page.media?.hero?.imageUrl}?width=1000&height=600&format=webp 1000w,${page.media?.hero?.imageUrl}?width=2000&height=1200&format=webp 2000w`" sizes="50vw">
        <source media="(min-width:1440px)" :srcset="`${page.media?.hero?.imageUrl}?width=720&height=600&format=webp 720w,${page.media?.hero?.imageUrl}?width=1440&height=1200&format=webp 720w`" sizes="50vw">
        <source media="(min-width:1024px)" :srcset="`${page.media?.hero?.imageUrl}?width=512&height=600&format=webp 512w, ${page.media?.hero?.imageUrl}?width=768&height=900&format=webp 768w, ${page.media?.hero?.imageUrl}?width=1024&height=1200&format=webp 1024w`" sizes="50vw">
        <source media="(min-width:768px)" :srcset="`${page.media?.hero?.imageUrl}?width=768&height=698&format=webp 768w, ${page.media?.hero?.imageUrl}?width=1152&height=1047&format=webp 1152w, ${page.media?.hero?.imageUrl}?width=1536&height=1396&format=webp 1536w`" sizes="100vw">
        <img :src="`${page.media?.hero?.imageUrl}?width=320&height=325&format=webp`" :srcset="`${page.media?.hero?.imageUrl}?width=320&height=325&format=webp 320w, ${page.media?.hero?.imageUrl}?width=640&height=650&format=webp 640w, ${page.media?.hero?.imageUrl}?width=960&height=975&format=webp 960w`" sizes="100vw" :alt="page.media?.hero?.alt || page.media?.hero?.imageUrl" width="320" height="325">
      </picture>
      <div class="absolute inset-0 bg-gradient-to-t from-black/90 to-transparent to-65% text-white lg:bg-none">
        <WidthWrapper class="grid h-full grid-cols-12">
          <div class="col-span-12 flex flex-col justify-end lg:col-span-6 lg:flex-row lg:items-center lg:justify-normal lg:p-20 lg:px-130">
            <div>
              <div class="px-16 lg:p-0">
                <div class="mb-25 flex h-[70px] items-center justify-start">
                  <img :src="page?.logo?.imageUrl" width="106" height="70" class="h-full w-auto max-w-[260px] invert" :alt="`${page.title} logo`">
                </div>
                <div class="mb-25 flex flex-wrap gap-6 text-14 font-semibold lg:mb-40">
                  <div v-for="(feature, key) in page.features" :key="key" class="flex items-center rounded-lg border border-white/40 px-12 py-8 lg:px-8 lg:py-5">
                    {{ feature }}
                    <LazySharedIcon
                      v-if="['pling', 'print'].includes(String(key))"
                      size="size-16"
                      :name="String(key)"
                      class="ml-6"
                    />
                  </div>
                </div>
              </div>
              <div class="hidden px-16 pb-35 pt-25 lg:block lg:p-0">
                <h1 class="mb-8 text-20 font-semibold leading-125p">
                  {{ page.title }}
                </h1>
                <div class="mb-35 line-clamp-3" v-html="page.tagline" />
                <Button
                  to="#produkter"
                  class="!border-primary !bg-primary !text-primary-text hover:!border-primary-darken hover:!bg-primary-darken"
                >
                  Køb abonnement
                  <LazySharedIcon
                    name="chevron-right"
                    size="size-24"
                  /></Button>
              </div>
            </div>
          </div>
        </WidthWrapper>
      </div>
    </div>
    <div class="relative px-16 pb-35 pt-25 text-white lg:hidden lg:p-0">
      <picture class="absolute inset-0 -z-10">
        <source media="(min-width:2000px)" :srcset="`${page.media?.hero?.imageUrl}?width=1000&height=600&format=webp 1000w,${page.media?.hero?.imageUrl}?width=2000&height=1200&format=webp 2000w`" sizes="50vw">
        <source media="(min-width:1440px)" :srcset="`${page.media?.hero?.imageUrl}?width=720&height=600&format=webp 720w,${page.media?.hero?.imageUrl}?width=1440&height=1200&format=webp 720w`" sizes="50vw">
        <source media="(min-width:1024px)" :srcset="`${page.media?.hero?.imageUrl}?width=512&height=600&format=webp 512w, ${page.media?.hero?.imageUrl}?width=768&height=900&format=webp 768w, ${page.media?.hero?.imageUrl}?width=1024&height=1200&format=webp 1024w`" sizes="50vw">
        <source media="(min-width:768px)" :srcset="`${page.media?.hero?.imageUrl}?width=768&height=698&format=webp 768w, ${page.media?.hero?.imageUrl}?width=1152&height=1047&format=webp 1152w, ${page.media?.hero?.imageUrl}?width=1536&height=1396&format=webp 1536w`" sizes="100vw">
        <img :src="`${page.media?.hero?.imageUrl}?width=320&height=548&format=webp`" :srcset="`${page.media?.hero?.imageUrl}?width=320&height=548&format=webp 320w, ${page.media?.hero?.imageUrl}?width=480&height=820&format=webp 480w, ${page.media?.hero?.imageUrl}?width=640&height=1095&format=webp 640w, ${page.media?.hero?.imageUrl}?width=800&height=1368&format=webp 800w`" sizes="100vw" :alt="page.media?.hero?.alt || page.media?.hero?.imageUrl" width="393" height="672" class="h-full w-full">
      </picture>
      <div class="absolute inset-0 -z-10 bg-black/30 backdrop-blur-3xl" />
      <h1 class="mb-8 text-20 font-semibold leading-125p">
        {{ page.tagline }}
      </h1>
      <div class="mb-35 line-clamp-3" v-html="page.teaser" />
      <Button
        to="#produkter"
        class="w-full !border-primary !bg-primary !text-primary-text hover:!bg-primary-darken"
      >
        Køb abonnement
        <LazySharedIcon
          name="chevron-right"
          size="size-24"
        /></Button>
    </div>
  </section>
  <ContentComponents :components="page.mainContentArea" />
</template>

<script lang="ts" setup>
import type { ContentPageBrand } from '@aller/nuxt-allerservice-sdk/transformers';

const props = defineProps({
  page: {
    type: Object as PropType<ContentPageBrand>,
    required: true,
  },
});

useHead({
  style: [
    {
      innerHTML: `:root {--color-primary: ${props.page.colors.primary.background}; --color-primary-text: ${props.page.colors.primary.text}}`,
    },
  ],
});
</script>
