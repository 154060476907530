<template>
  <component
    :is="content.component"
    :page="content.data"
  />
</template>

<script lang="ts" setup>
import {
  BlockTransformer,
  BrandTransformer,
  GuideTransformer,
  LandingpageTransformer,
  PageTransformer,
  ProductWithVariantsTransformer,
  type ContentPageComponents,
} from '@aller/nuxt-allerservice-sdk/transformers';
import type { CommerceProduct, OptimizelyContentBlock, OptimizelyContentResponse } from '@aller/nuxt-allerservice-sdk/types';

/**
 * We do not want the slug file to deal with anything that has a file extension (e.g. old image paths).
 * By removing file paths we also save the request to the backend.
 */
definePageMeta({
  validate: (route) => !(/^.*\.(jpg|jpeg|png|gif|ico|json|rss|xml|svg|js|css|mjs|woff|woff2|pdf)$/).test(route.fullPath),
});

// Get brand name and site URL from environment variables.
const { brandName, siteUrl } = useRuntimeConfig().public as Record<string, string>;

// Get page data from API.
const data = useRoute().path === '/' ? useState<OptimizelyContentResponse>('start') : await useOptimizely().content().byPath(useRoute().path, ['*']);

// Transform response to basic page details.
const page = PageTransformer(data.value);

// Special case for viewing a product.
let product: Ref<CommerceProduct>;
if (['DigitalProduct', 'PrintProduct'].includes(page.type!)) {
  product = await useCommerce().products().id(page.guid as string);
}

const content = computed(() => {
  switch (page.type) {
    case 'DigitalProduct':
    case 'PrintProduct':
      return {
        component: resolveComponent('PageTypeProduct'),
        data: ProductWithVariantsTransformer(product?.value),
      };
    case 'BrandPage':
      return {
        component: resolveComponent('PageTypeBrand'),
        data: BrandTransformer(data.value),
      };
    case 'GuideArticlePage':
      return {
        component: resolveComponent('PageTypeGuide'),
        data: GuideTransformer(data.value),
      };
    case 'LandingPage':
      return {
        component: resolveComponent('PageTypeLandingpage'),
        data: {
          ...LandingpageTransformer(data.value),
          contentComponents: data.value.mainContentArea?.map((item: any) => BlockTransformer(item.contentLink.expanded as OptimizelyContentBlock)),
        },
      };
    default:
      return {
        component: page.type === 'CustomerServicePage' || page.type === 'FaqPage' ? resolveComponent('PageTypeComponentsGradient') : resolveComponent('PageTypeComponents'),
        data: {
          ...page,
          contentComponents: data.value.mainContentArea?.map((item: any) => BlockTransformer(item.contentLink.expanded as OptimizelyContentBlock)),
        } as ContentPageComponents,
      };
  }
});

// Set SEO details of page.
useSeoMeta({
  title: `${page.meta?.title || page.title} | ${brandName}`,
  description: page.meta?.description,
  keywords: page.meta?.keywords,
  robots: page.meta?.robots,
  ogUrl: `${siteUrl}${convertToRelativeUrl(page.meta?.canonical as string)}`,
  ogSiteName: brandName,
  ogTitle: `${page.meta?.title} | ${brandName}`,
  ogType: 'website',
  ogDescription: page.meta?.description,
  ogImage: page.meta?.image,
  articlePublishedTime: page.meta?.created,
  articleModifiedTime: page.meta?.changed,
});

// Set canonical URL of page.
useHead({
  link: [
    {
      rel: 'canonical',
      href: `${siteUrl}${convertToRelativeUrl(page.meta?.canonical as string)}`,
    },
  ],
});

onMounted(() => {
  useGTM().pushPageView({
    page_id: page.guid,
    page_title: page.meta?.title || page.title,
    page_type: page.type,
    page_url: `${siteUrl}${useRoute().fullPath}`,
  });
});
</script>
