<template>
  <WidthWrapper
    type="main"
  >
    <PageTitle
      :title="page.title"
    />
  </WidthWrapper>
  <ContentComponents
    :components="page.contentComponents"
  />
</template>

<script lang="ts" setup>
import type { ContentPageComponents } from '@aller/nuxt-allerservice-sdk/transformers';

const props = defineProps({
  page: {
    type: Object as PropType<ContentPageComponents>,
    required: true,
  },
});

const { page } = props;

const { brandName } = useRuntimeConfig().public as Record<string, string>;

// Set SEO details of page.
useSeoMeta({
  title: `${page.meta?.title || page.title} | ${brandName}`,
});
</script>
